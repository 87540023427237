import React, { memo } from 'react';

import { Handle } from '@xyflow/react';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import {Box, Card, Typography} from '@mui/material';

export default memo(({ data, isConnectable }) => {

  
  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: 'gray' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
    <Card sx={{p: 1}}>
        <Box align='center'>
          <ProfileIcon/>
        </Box>
        <Typography align="center" variant="body2" sx={{ color: 'text.secondary' }}>
          {data.label}
        </Typography>
    </Card>
      <Handle
        type="source"
        position="right"
        id="a"
        style={{ background: 'gray' }}
        isConnectable={isConnectable}
      />
    </>
  );
});