const PATHS = {
	all: "*",
	root: "/",
	login: "/login",
	logout: "/logout",
	profile: "/profile",

	// CMS
	defaultAfterLogin: "/",

	view_post: "/posts/post/:postId",

    // CNVS
	cnvs: "/cnvs"
};

export {PATHS};
