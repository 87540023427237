import * as React from "react";
import {BrowserRouter} from "react-router-dom";
import Router from "./router";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import {useFetchUIConfig} from "./hooks/config/useFetchUIConfig.js";

const theme = createTheme({});


function App() {
	
	useFetchUIConfig();

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
					<Router/>
			</ThemeProvider>
		</BrowserRouter>
    );

}

export default App;
