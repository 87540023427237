import React from 'react';
import CommonPage from "../common/CommonPage";

const HomePage = () => {

	return (
		<CommonPage>
			Hello world
		</CommonPage>
	);

}

export default HomePage;