import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CALL_STATUS} from "./constants";
import configUIAPI from "../../api/config_ui";

const initialState = {
	status: CALL_STATUS.IDLE,
	error: null,
	config: {
		version: '',
		timestamp: '',
		menu: null,
		mainPageId: null
	}
}

export const fetchConfigUI = createAsyncThunk('config_ui/FETCH_CONFIG_UI', async () => {
		const response = await configUIAPI.getConfigUI();
		return response;
	}
);

const configUISlice = createSlice({
	name: "config_ui",
	initialState,
	reducers: {
		reset: initialState
		// pending: (state, action) => {
		// 	state.loading = CALL_STATUS.PENDING;
		// },
		// success: (state, action) => {
		// 	state.loading = CALL_STATUS.SUCCESS;
		// },
		// error: (state, action) => {
		// 	state.loading = CALL_STATUS.ERROR;
		// 	state.error = "Some error - todo";
		// }
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchConfigUI.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;
			})
			.addCase(fetchConfigUI.fulfilled, (state, action) => {
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				state.config.version = action.payload.version;
				state.config.timestamp = action.payload.timestamp;
				state.config.mainPageId = action.payload.mainPageId;
				state.config.menu = action.payload.menu;
			})
			.addCase(fetchConfigUI.rejected, (state, action) => {
				//state.status = CALL_STATUS.ERROR;
				//state.error = action.message;
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				state.config.version = "default";
				state.config.timestamp = 0;
			})
	}
});

const {actions, reducer} = configUISlice;

export const {
	reset
} = actions;

export default reducer;