import NavBar from "../../navbar/NavBar";
import {Box, Container} from "@mui/material";
import Footer from "../../footer/Footer";

const CommonPage = ({children}) => {

	return (
		<Box>
			<NavBar/>
			<Container sx={{mt: 12, mb: 3}}>
				<Container>
					{children}
				</Container>
			</Container>
			<Container>
				<Footer/>
			</Container>
		</Box>
	);

}

export default CommonPage;