import React from 'react';
import CommonPage from "../common/CommonPage";
import {useSelector} from "react-redux";
import Grid from '@mui/material/Grid2';

const ProfilePage = () => {

	const auth = useSelector(state => state.auth);

	return (
		<CommonPage>
			<Grid container justifyContent="center">
               <Grid size={6}>login</Grid>
			   <Grid size={6}>{auth.subject}</Grid>
			</Grid>
			<Grid container justifyContent="center">
               <Grid size={6}>roles</Grid>
			   <Grid size={6}>{auth.roles}</Grid>
			</Grid>
		</CommonPage>
	);

}

export default ProfilePage;