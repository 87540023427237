import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CALL_STATUS} from "./constants";
import postsAPI from "../../api/posts";

const initialState = {
	status: CALL_STATUS.IDLE,
	error: null,
	posts: null
}

export const fetchPost = createAsyncThunk('posts/FETCH_POST', async (postId) => {
	const response = await postsAPI.getPostAPI(postId);
		return response;
	}
);

const postsSlice = createSlice({
	name: "posts",
	initialState,
	reducers: {
		reset: initialState
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPost.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;
			})
			.addCase(fetchPost.fulfilled, (state, action) => {
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
                state.post = action.payload;
			})
			.addCase(fetchPost.rejected, (state, action) => {
				state.status = CALL_STATUS.ERROR;
				state.error = null;
			})
	}
});

const {actions, reducer} = postsSlice;

export const {
	reset
} = actions;

export default reducer;