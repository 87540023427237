import React from "react";
import {Typography} from "@mui/material";
import Loading from "../../../common/loading/loading";
import CommonPage from "../common/CommonPage";
import { useParams } from "react-router-dom";
import {CALL_STATUS} from "../../../store/reducers/constants";
import {useFetchPost} from '../../../hooks/posts/useFetchPost';

const ViewPostPage = () => {

	const {postId} = useParams();	
	const data = useFetchPost(postId);
	
	if (data.status === CALL_STATUS.IDLE)
		return <CommonPage><Typography>Performing request...</Typography></CommonPage>
	if (data.status === CALL_STATUS.PENDING)
		return (
			<CommonPage>
				<Typography>Requesting...</Typography>
				<Loading/>
			</CommonPage>
		);
	if (data.status === CALL_STATUS.ERROR)
		return <CommonPage><Typography>An error occrus</Typography></CommonPage>

	return (
	  <CommonPage>
		<div dangerouslySetInnerHTML={{ __html: data.post.content }}></div>
	  </CommonPage>
	)

}

export default ViewPostPage;