import React from "react";
import Grid from '@mui/material/Grid2';
import {Box, Container, Typography} from "@mui/material";
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from "@mui/material/IconButton";
import Divider from '@mui/material/Divider';
import {useUIConfig} from '../../hooks/config/useUIConfig';

const Footer = () => {

	const config = useUIConfig();

	if(true) {
		return (
			<Container>
			    <Divider/>
				<Grid container>
					<Grid size={4}>
						<Box>
							<Typography variant="subtitle1" color='gray' align="left" sx={{p: 1}}>Copyright © 2024 BlockWit</Typography>
						</Box>
					</Grid>
					<Grid size={4}>
						<Box>
							<Typography variant="subtitle1" color='gray' align="center" sx={{p: 1}}>{config.config.version}</Typography>		
						</Box>
					</Grid>
					<Grid size={4}>
						<Box align='right'>
							<IconButton color="inherit" href="/">
								<TelegramIcon/>
							</IconButton>
							<IconButton color="inherit" href="/">
								<FacebookIcon/>
							</IconButton>
							<IconButton color="inherit" href="/">
								<InstagramIcon/>
							</IconButton>
							<IconButton color="inherit" href="/">
								<TwitterIcon/>
							</IconButton>
							<IconButton color="inherit" href="/">
								<YouTubeIcon/>
							</IconButton>
						</Box>
					</Grid>
				</Grid>
			</Container>
		);
	}

}

export default Footer;