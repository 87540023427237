import axiosInstance from "./setup/axiosInstance";
import ENDPOINTS from "./setup/constants";

const auth = async (credentials) => {
	return axiosInstance.post(
		ENDPOINTS.auth,
		JSON.stringify(credentials),
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => res.data);
};

export const logout = () => ({
	type: 'auth/logout'
})

const authAPI = {
	auth: auth,
	logout: logout
}

export default authAPI;

