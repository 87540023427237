import React from 'react';
import {Typography, Box} from "@mui/material";
import Loading from "../../../common/loading/loading";
import CommonPage from "../common/CommonPage";
import {useSelector} from "react-redux";
import {CALL_STATUS} from "../../../store/reducers/constants";
import { useFetchContactWithRelations } from '../../../hooks/contacts/useFetchContactWithRelations';
//import NodeAsHandleFlow from './graphrf/NodeAndHandleFlow';
import NodeAsHandleFlowELKjs from './graphrf/NodeAndHandleFlowELKjs';

import NavBar from "../../navbar/NavBar";
import Footer from "../../footer/Footer";


const CnvsGraphPage = () => {

	const {subject} = useSelector(state => state.auth);
	//const subjectContactId = "dummy_v1-" + subject; 
	const subjectContactId = "tg_json_export-" + subject; 

	const cnvs = useFetchContactWithRelations(subjectContactId);

	if (cnvs.status === CALL_STATUS.IDLE)
		return <CommonPage><Typography>Performing request...</Typography></CommonPage>
	if (cnvs.status === CALL_STATUS.PENDING)
		return (
			<CommonPage>
				<Typography>Requesting...</Typography>
				<Loading/>
			</CommonPage>
		);
	if (cnvs.status === CALL_STATUS.ERROR)
		return <CommonPage><Typography>An error occrus</Typography></CommonPage>

	return (
		<Box>
			<NavBar/>
			<NodeAsHandleFlowELKjs/>
			<Footer/>
		</Box>
	);

}

export default CnvsGraphPage;