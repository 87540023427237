import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {fetchConfigUI} from "../../store/reducers/config_ui.reducer";
import {useUIConfig} from "./useUIConfig";

export const useFetchUIConfig = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchConfigUI());
	}, [dispatch]);

	const configUI = useUIConfig();

	return configUI;
}