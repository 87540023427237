import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {PATHS} from "./paths";

import HomePage from "../components/pages/HomePage/HomePage";
import LoginPage from "../components/pages/LoginPage/LoginPage";
import LogoutPage from "../components/pages/LogoutPage/LogoutPage";
import ProfilePage from "../components/pages/ProfilePage/ProfilePage";
import ViewPostPage from "../components/pages/ViewPostPage/ViewPostPage";
import CnvsGraphPage from "../components/pages/CnvsGraphPage/CnvsGraphPage";

const Router = () => {
	return (
		<Routes>
			<Route exact path={PATHS.root} element={<HomePage/>}/>
			<Route exact path={PATHS.cnvs} element={<CnvsGraphPage/>}/>
			<Route exact path={PATHS.login} element={<LoginPage/>}/>
			<Route exact path={PATHS.profile} element={<ProfilePage/>}/>
			<Route exact path={PATHS.logout} element={<LogoutPage/>}/>
			<Route path={PATHS.view_post} element={<ViewPostPage />}/>
			<Route path={PATHS.all} element={<Navigate to={PATHS.root} replace/>}/>
		</Routes>
	);
}

export default Router;