import {useDispatch} from "react-redux";
import {useEffect, useRef} from "react";
import {fetchContactRelations, fetchContact} from "../../store/reducers/contacts.reducer";
import {useContact} from "./useContact";

export const useFetchContactWithRelations = (contactId) => {
	const dispatch = useDispatch();

	const {contacts} = useContact();

	const targetContact = contacts[contactId];

	useEffect(() => {
		if(targetContact == null) {
			dispatch(fetchContact(contactId));
		} else {
			// why it called twic
			dispatch(fetchContactRelations(contactId));
		}
	}, [dispatch, contactId, targetContact]);

	const contact = useContact();

	return contact;
}