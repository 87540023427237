import axios from "axios";
import {getTokenFromStorage} from "../authStorage/storageTokenOperations";

const axiosInstance = axios.create({
	baseURL: window.location.origin,
	// baseURL: 'http://localhost',
});

axiosInstance.interceptors.request.use((config) => {
	const token = getTokenFromStorage();
	if (token !== null) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

export default axiosInstance;