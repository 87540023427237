import React from "react";
import {CircularProgress} from "@mui/material";


const Loading = () => {
	return (
		<div style={{textAlign: 'center'}}>
			<CircularProgress/>
		</div>
	)
}

export default Loading;