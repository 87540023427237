import NavbarMenuItem from "./NavbarMenuItem/NavbarMenuItem";
import {useUIConfig} from '../../../hooks/config/useUIConfig';
import {PATHS} from '../../../router/paths';

const NavbarMenu = () => {

	const config = useUIConfig();
	
	if(config.config.menu == null)
		return <></>;

	const staticMenu = [
		// {
		// 	name: "home",
		// 	link: PATHS.root
		// }
	];

	const menu = staticMenu.concat(config.config.menu);

	return (
		<>
			<NavbarMenuItem to={PATHS.cnvs} name={"cnvs"} auth={true}/>

			{menu.map(({name, link}) => (
				<NavbarMenuItem key={link} to={link} name={name}/>
			))}
		</>
	);

}

export default NavbarMenu;