import parseJWT from "./parseJWT";

// TODO: Must be replaced with profile
const subjectFromJWT = (token) => {
	const parsedToken = parseJWT(token);
	if(parsedToken == null)
		return null;
	return parsedToken.sub;
}

export default subjectFromJWT;