import axiosInstance from "./setup/axiosInstance";
import ENDPOINTS from "./setup/constants";

const getPostAPI = async (postId) => {
	return axiosInstance.get(ENDPOINTS.post + "/" + postId)
		.then((res) => {
			return res.data;
		});
};

const postsAPI = {
	getPostAPI: getPostAPI
}

export default postsAPI;

