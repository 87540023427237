import axiosInstance from "./setup/axiosInstance";
import ENDPOINTS from "./setup/constants";

const getConfigUI = async () => {
	return axiosInstance.get(ENDPOINTS.config_ui)
		.then((res) => {
			return res.data;
		});
};

const configUIAPI = {
	getConfigUI: getConfigUI
}

export default configUIAPI

