import React, {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "../../../api/auth";
import {useIsAuthenticated} from "../../../hooks/auth/useIsAuthenticated";
import Loading from "../../../common/loading/loading";
import {PATHS} from "../../../router/paths";

const Logout = () => {

	const authenticated = useIsAuthenticated()
	const dispatch = useDispatch();

	useEffect(() => {
		if (authenticated) {
			dispatch(logout());
		}
	});

	if (authenticated)
		return <Loading/>

	return <Navigate to={PATHS.login}/>;
}

export default Logout;