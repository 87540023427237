import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CALL_STATUS} from "./constants";
import authAPI from "../../api/auth";
import {isTokenInStorage, removeTokenFromStorage, setTokenToStorage} from '../../api/authStorage/storageTokenOperations';
import rolesFromJWT from "../../api/authStorage/rolesFromJWT";
import rolesFromJWTStorage from "../../api/authStorage/rolesFromJWTStorage";
import subjectFromJWTStorage from "../../api/authStorage/subjectFromJWTStorage";
import subjectFromJWT from "../../api/authStorage/subjectFromJWT";


const initialState = {
	status: CALL_STATUS.IDLE,
	error: null,
	authenticated: isTokenInStorage(),
	roles: rolesFromJWTStorage(),
	subject: subjectFromJWTStorage()
}

// export const logout = createAsyncThunk('auth/logout', async () => {
// 	const response = authAPI.logout();
// 	return response;
// });

export const fetchAuthToken = createAsyncThunk('auth/fetch', async (credentials) => {
		const response = await authAPI.auth(credentials);
		return response;
});

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		reset: initialState,
		logout: (state, action) => {
			state.status = CALL_STATUS.SUCCESS;
			state.error = null;
			state.authenticated = false;
			state.roles = [];
			state.subject = null;
			removeTokenFromStorage();
		}
		// pending: (state, action) => {
		// 	state.loading = CALL_STATUS.PENDING;
		// },
		// success: (state, action) => {
		// 	state.loading = CALL_STATUS.SUCCESS;
		// },
		// error: (state, action) => {
		// 	state.loading = CALL_STATUS.ERROR;
		// 	state.error = "Some error - todo";
		// }
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAuthToken.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;
				state.authenticated = false;
				state.roles = [];
				state.subject = null;
				removeTokenFromStorage();
			})
			.addCase(fetchAuthToken.fulfilled, (state, action) => {
				const token = action.payload.token;
				setTokenToStorage(action.payload.token);

				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				state.roles = rolesFromJWT(token);
				state.subject = subjectFromJWT(token);
				state.authenticated = true;
			})
			.addCase(fetchAuthToken.rejected, (state, action) => {
				state.status = CALL_STATUS.ERROR;
				state.error = action.message;
				state.authenticated = false;
				state.roles = [];
				state.subject = null;
			})
	}
});

const {actions, reducer} = authSlice;

export const {
	reset
} = actions;

export default reducer;