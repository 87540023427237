import ELK from 'elkjs/lib/elk.bundled.js';
import React, { useCallback } from 'react';
import {useContact} from '../../../../hooks/contacts/useContact';

import {
  ReactFlow,
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,

  Background,
  BackgroundVariant
} from '@xyflow/react';
 
import { mapCNVS } from './InitialElements';
import '@xyflow/react/dist/style.css';
 
import FloatingEdge from './FloatingEdge';
import ContactNode from './ContactNode';

const edgeTypes = {
  floating: FloatingEdge
};

const nodeTypes = {
  contactNode: ContactNode
};

const elk = new ELK();
 
const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': 100,
    'elk.spacing.nodeNode': 80,
  };
 
  const getLayoutedElements = useCallback((options) => {
    const layoutOptions = { ...defaultOptions, ...options };
    const graph = {
      id: 'root',
      layoutOptions: layoutOptions,
      children: getNodes().map((node) => ({
        ...node,
        width: node.measured.width,
        height: node.measured.height,
      })),
      edges: getEdges(),
    };
 
    elk.layout(graph).then(({ children }) => {
      // By mutating the children in-place we saves ourselves from creating a
      // needless copy of the nodes array.
      children.forEach((node) => {
        node.position = { x: node.x, y: node.y };
      });
 
      setNodes(children);
      window.requestAnimationFrame(() => {
        fitView();
      });
    });
  }, []);
 
  return { getLayoutedElements };
};
 
const NodeAsHandleFlowInternal = () => {
  const cnvs = useContact();
  const { nodes: initialNodes, edges: initialEdges } = mapCNVS(cnvs);

  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, , onEdgesChange] = useEdgesState(initialEdges);
  const { getLayoutedElements } = useLayoutedElements();
 
  
  return (
    <div style={{ height: 1000 }}>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      fitView
    >
      <Background color="#eee" bgColor="#EFFFF5" variant={BackgroundVariant.Lines} />
      <Panel position="bottom-right">
        <button
          onClick={() =>
            getLayoutedElements({
              'elk.algorithm': 'layered',
              'elk.direction': 'DOWN',
            })
          }
        >
          vertical layout
        </button>
        <button
          onClick={() =>
            getLayoutedElements({
              'elk.algorithm': 'layered',
              'elk.direction': 'RIGHT',
            })
          }
        >
          horizontal layout
        </button>
        <button
          onClick={() =>
            getLayoutedElements({
              'elk.algorithm': 'org.eclipse.elk.radial',
            })
          }
        >
          radial layout
        </button>
        <button
          onClick={() =>
            getLayoutedElements({
              'elk.algorithm': 'org.eclipse.elk.force',
            })
          }
        >
          force layout
        </button>
      </Panel>
    </ReactFlow>
    </div>
  );
};
 
export default function () {
  return (
    <ReactFlowProvider>
      <NodeAsHandleFlowInternal/>
    </ReactFlowProvider>
  );
}