import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CALL_STATUS} from "./constants";
import contactsAPI from "../../api/contacts";
// import { enableMapSet } from 'immer';

// enableMapSet();

const initialState = {
	status: CALL_STATUS.IDLE,
	error: null,
	contacts: {},
	relations: []
}

export const fetchContact = createAsyncThunk('contacts/FETCH_CONTACT', async (contactId) => {
	const response = await contactsAPI.contact(contactId);
		return response;
	}
);


export const fetchContactRelations = createAsyncThunk('contacts/FETCH_CONTACT_RELATIONS', async (contactId) => {
	const response = await contactsAPI.relations(contactId);
		return response;
	}
);

const contactsSlice = createSlice({
	name: "contacts",
	initialState,
	reducers: {
		reset: initialState
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchContact.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;				
			})
			.addCase(fetchContact.fulfilled, (state, action) => {
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				state.contacts = {... state.contacts, [action.payload.data.id] : {...action.payload.data, relations: false}};	
			})
			.addCase(fetchContact.rejected, (state, action) => {
				state.status = CALL_STATUS.ERROR;
				state.error = null;
			})
			.addCase(fetchContactRelations.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;				
			})
			.addCase(fetchContactRelations.fulfilled, (state, action) => {
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				// why it called twice
				if(!state.contacts[action.payload.contactId].relations) {
					const newRelaitons = action.payload.data.contacts.map(c  => ({ from: action.payload.contactId, to: c.id }));
					state.relations = [...state.relations, ...newRelaitons];	
					const newContacts = Object.fromEntries(action.payload.data.contacts.map(c => [c.id, c]));
					state.contacts = {...state.contacts, ...newContacts};	
					state.contacts[action.payload.contactId].relations = true;
				}
			})
			.addCase(fetchContactRelations.rejected, (state, action) => {
				state.status = CALL_STATUS.ERROR;
				state.error = null;
			})
	}
});

const {actions, reducer} = contactsSlice;

export const {
	reset
} = actions;

export default reducer;