import {AppBar, Box, Container, Toolbar} from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Login';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import {NavLink} from "react-router-dom";
import {PATHS} from "../../router/paths";
import NavbarMenu from "./NavbarMenu/NavbarMenu";
import NavbarMenuItem from "./NavbarMenu/NavbarMenuItem/NavbarMenuItem";
import logo from '../../logo_black_single.png';

const NavBar = () => {
	return (
		<AppBar position="fixed" sx={{
			background: 'white',
			color: "gray"
		}} elevation={3}>
			<Container>
				<Toolbar>
					<Box sx={{flexGrow: 1}}>
						<NavLink to={PATHS.root}>
							<Box component="img" src={logo} alt='BlockWit' sx={{
									height: '45px',
									marginLeft: 0,
									marginRight: '20px'
							}}/>
						</NavLink>
					</Box>
					<Box sx={{flexGrow: 100}}>
						<NavbarMenu/>
					</Box>
					<NavbarMenuItem to={PATHS.login} icon={<LoginIcon/>} auth={false}/>
					<NavbarMenuItem to={PATHS.profile} icon={<ProfileIcon/>} auth={true}/>
					<NavbarMenuItem to={PATHS.logout} icon={<LogoutIcon/>} auth={true}/>
				</Toolbar>
			</Container>
		</AppBar>
	);

}

export default NavBar;