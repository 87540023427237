import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {fetchPost} from "../../store/reducers/posts.reducer";
import { usePost } from "./usePost";

export const useFetchPost = (postId) => {
	const dispatch = useDispatch();
	
	useEffect(() => {
		dispatch(fetchPost(postId));
	}, [dispatch, postId]);

	const post = usePost();

	return post;
}