import {configureStore} from "@reduxjs/toolkit";
import config_uiReducer from "./reducers/config_ui.reducer";
import authReducer from "./reducers/auth.reducer";
import postReducer from "./reducers/posts.reducer";
import contactsReducer from "./reducers/contacts.reducer";



const store = configureStore({
	reducer: {
		config_ui: config_uiReducer,
		auth: authReducer,
		post: postReducer,
		contacts: contactsReducer
	},
	devTools: true
});

export default store;
