import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button} from "@mui/material";
import {isAuthenticated} from "../../../../api/authStorage/isAuthenticated";

const NavbarMenuItem = ({auth = null, to, name, icon}) => {
	
	const location = useLocation();
	const navigate = useNavigate();

	const active = location.pathname === to;

	const authenticated = isAuthenticated();

	if ((auth === true && !authenticated) || (auth === false && authenticated)) {
		return <></>;
	}

	const iconButtonStyle = active ? {mr: 0, color: 'black'} : {mr: 0};

	return (
		<Button color="inherit" sx={iconButtonStyle} onClick={() => navigate(to, {state: {from: location.pathname}})}>
			{icon}
			{name}
		</Button>
	);

};

export default NavbarMenuItem;
